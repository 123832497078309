
import { Container, Row, Col } from "react-bootstrap";
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import packAutoImg1 from "../assets/img/packAutoImg/packAutoImg1.jpg";
import packAutoImg11 from "../assets/img/packAutoImg/packAutoImg11.jpg";
import packAutoImg12 from "../assets/img/packAutoImg/packAutoImg12.jpg";
import packAutoImg13 from "../assets/img/packAutoImg/packAutoImg13.jpg";
import packAutoImg14 from "../assets/img/packAutoImg/packAutoImg14.jpg";
import TrackVisibility from 'react-on-screen';
import Carousel from 'react-bootstrap/Carousel';

export const BannerPackAuto = () => {

  let data =[
    {
      id: 1,
      imgSrc: packAutoImg1,
      imgSrc1: packAutoImg11,
      imgSrc2: packAutoImg12,
      imgSrc3: packAutoImg13,
      imgSrc4: packAutoImg14,
      imgText: "Bugatti Divo",
      mechLoc: "Concessionario Lusso",
    },
  ]

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const [tempimgSrc1, setTempImgSrc1] = useState('');
  const [tempimgSrc2, setTempImgSrc2] = useState('');
  const [tempimgSrc3, setTempImgSrc3] = useState('');
  const [tempimgSrc4, setTempImgSrc4] = useState('');
  const [tempSrcText, setTempSrcText] = useState('');
  const [tempMechanicLocation, setTempMechanicLocation] = useState('');
  
  const [index, setIndex] = useState(0);
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const getImg = (imgSrc, imgSrc1, imgSrc2, imgSrc3, imgSrc4, imgText, mechLoc) =>{
    setTempImgSrc(imgSrc);
    setTempImgSrc1(imgSrc1);
    setTempImgSrc2(imgSrc2);
    setTempImgSrc3(imgSrc3);
    setTempImgSrc4(imgSrc4);
    setTempSrcText(imgText);
    setTempMechanicLocation(mechLoc);
    setModel(true);
  }

  return (

    <div className="bannerPackAutoContainer">
      
      <h1>PACK AUTO</h1>
      <div className={model? "bannerPackAutomodel open" : "model"}>
        <Container className="bannerPackAutoContainerRow">
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) =>
                
                  <div className="bannerPackAutoItemImage">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={tempimgSrc}
                          alt="First slide"
                        />
                        <Carousel.Caption>
                          <h3></h3>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={tempimgSrc1}
                          alt="Second slide"
                        />

                        <Carousel.Caption>
                          <h3></h3>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={tempimgSrc2}
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                          <h3></h3>
                         </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={tempimgSrc3}
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                          <h3></h3>
                         </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={tempimgSrc4}
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                          <h3></h3>
                         </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                }
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={4}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className="bannerPackAutoItem">
                    <h3>{tempSrcText}</h3>
                    <h5>📍 {tempMechanicLocation}</h5>
                  </div>
                }
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
        <CloseIcon className="bannerPackAutoimgClose" src={CloseIcon} onClick={() => setModel(false)} />
      </div>
      <section className="bannerPackAuto">
        
        {data.map((item, index) => {
          return(
            <div className="bannerPackAutopics" key={index} onClick={() => getImg(item.imgSrc, item.imgSrc1, item.imgSrc2, item.imgSrc3, item.imgSrc4, item.imgText, item.mechLoc)}>
              
              <img className="bannerPackAutopicsImg" src={item.imgSrc} style={{width:'100%'}} />
            </div>
          )
        })}
        
      </section>
    </div>
  )
}