import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import navIcon1 from '../assets/img/instagram.png';
import navIcon2 from '../assets/img/tik-tok.png';
import navIcon3 from '../assets/img/twitch.png';

const valueOfPage = null

export const NavBar = () => {

  //const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);




  /*
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])
  


  const onUpdateActiveLink = (value) => {
    valueOfPage = value
    setActiveLink(value);
  }*/

  return (
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/home" className="navbar-link" >HOME</Nav.Link>
              <Nav.Link href="/team" className="navbar-link" >TEAM</Nav.Link>
              <Nav.Link href="/streamers" className="navbar-link" >STREAMERS</Nav.Link>
              <Nav.Link href="/packauto" className="navbar-link" >PACK AUTO</Nav.Link>
              <Nav.Link href="/pacchetti" className="navbar-link" >PACCHETTI</Nav.Link>
              <Nav.Link href="/gallery" className="navbar-link" >GALLERY</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.instagram.com/_blowrp_/"><img src={navIcon1} alt="" /></a>
                <a href="https://www.tiktok.com/@_blowrp_"><img src={navIcon2} alt="" /></a>
                <a href="#"><img src={navIcon3} alt="" /></a>
              </div>
                <button className="vvd"><span>Discord</span></button>
              
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}