
import { Container, Row, Col } from "react-bootstrap";
import DexyTV94 from "../assets/img/DexyTV94.png";
import ImGoD from "../assets/img/ImGoD.jpg";
import navIcon1 from '../assets/img/twitch.png';
import navIcon2 from '../assets/img/instagram.png';
import navIcon3 from '../assets/img/tik-tok.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerStreamers = () => {
  

  return (
    
    <section className="bannerStreamers" id="home">
      <Container>
        <Row className="aligh-items-center" >
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerStreamersItemImage" >
                  <a href="#"><img src={DexyTV94} alt="Image" /></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className="BannerStreamersItem">
                  <h5>DexyTV94</h5>
                  <div className="BannerStreamerssocial-icon">
                    <a href="https://www.twitch.tv/dexytv94"><img src={navIcon1} alt="" /></a>
                    <a href="https://www.instagram.com/dexytv/"><img src={navIcon2} alt="" /></a>
                    <a href="https://www.tiktok.com/@dexytv94?lang=it"><img src={navIcon3} alt="" /></a>
                  </div>
                </div>
                }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      
    </section>
  )
}