
import { Container, Row, Col } from "react-bootstrap";
import uanama from "../assets/img/uanama.jpg";
import ImGoD from "../assets/img/ImGoD.jpg";
import navIcon1 from '../assets/img/instagram.png';
import navIcon2 from '../assets/img/tik-tok.png';
import navIcon3 from '../assets/img/twitch.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerTeam = () => {
  

  return (
    
    <section className="bannerTeam" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerTeamItemImage">
                  <a href="#"><img src={uanama} alt="Image" /></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className="BannerTeamItem">
                  <h5>uanama</h5>
                  <h5>🔵 Founder</h5>
                  <h5>🔵 Developer</h5>
                  <div className="BannerTeamsocial-icon">
                    <a href="https://www.instagram.com/uanamatrash/"><img src={navIcon1} alt="" /></a>
                    <a href="https://www.tiktok.com/@_uanamatrash_?lang=it-IT"><img src={navIcon2} alt="" /></a>
                    <a href="https://www.twitch.tv/uanamatrash"><img src={navIcon3} alt="" /></a>
                  </div>
                </div>
                }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerTeamItemImage">
                  <a href="#"><img src={ImGoD} alt="Image" /></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className="BannerTeamItem">
                  <h5>-_Im-GoD_-🚀🔥</h5>
                  <h5>🔵 Co-Founder</h5>
                  <div className="BannerTeamsocial-icon">
                    <a href="https://www.instagram.com/il.giamei.22.ll/"><img src={navIcon1} alt="" /></a>
                    <a href="https://www.tiktok.com/@il.giamei.22.ll"><img src={navIcon2} alt="" /></a>
                  </div>
                </div>
                }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}