import logo from './logo.svg';
import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { BannerHome } from "./components/BannerHome";
import { BannerTeam } from "./components/BannerTeam";
import { BannerStreamers } from "./components/BannerStreamers";
import { BannerGallery } from "./components/BannerGallery";
import { BannerPackAuto } from "./components/BannerPackAuto";
import { Skills } from "./components/Skills";
import { Footer } from "./components/Footer";

function App() {

  switch (window.location.pathname){
    case "/":
      return (
        <div className="App">
          <NavBar />
          
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/home":
      return (
        <div className="App">
          <NavBar />
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/team":
      return (
        <div className="App">
          <NavBar />
          <BannerTeam />
          <Footer />
        </div>
      );
      break
    case "/streamers":
      return (
        <div className="App">
          <NavBar />
          <BannerStreamers />
          <Footer />
        </div>
      );
      break
    case "/packauto":
      return (
        <div className="App">
          <NavBar />
          <BannerPackAuto />
          <Footer />
        </div>
      );
      break
    case "/pacchetti":
      return (
        <div className="App">
          <NavBar />
          <Banner />
          <Footer />
        </div>
      );
      break
    case "/gallery":
      return (
        <div className="App">
          <NavBar />
          <BannerGallery />
          <Footer />
        </div>
      );
      break
  }
  
}

export default App;
