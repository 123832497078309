
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import galleryImg1 from "../assets/img/bannerGalleryImg1.png";
import galleryImg2 from "../assets/img/bannerGalleryImg2.png";
import galleryImg3 from "../assets/img/bannerGalleryImg3.png";
import galleryImg4 from "../assets/img/bannerGalleryImg4.png";
import galleryImg5 from "../assets/img/bannerGalleryImg5.png";
import galleryImg6 from "../assets/img/bannerGalleryImg6.png";
import galleryImg7 from "../assets/img/bannerGalleryImg7.png";
import galleryImg8 from "../assets/img/bannerGalleryImg8.png";
import galleryImg9 from "../assets/img/bannerGalleryImg9.png";
import galleryImg10 from "../assets/img/bannerGalleryImg10.png";
import galleryImg11 from "../assets/img/bannerGalleryImg11.png";
import galleryImg12 from "../assets/img/bannerGalleryImg12.png";

export const BannerGallery = () => {

  let data =[
    {
      id: 1,
      imgSrc: galleryImg1,
    },
    {
      id: 2,
      imgSrc: galleryImg2,
    },
    {
      id: 3,
      imgSrc: galleryImg3,
    },
    {
      id: 4,
      imgSrc: galleryImg4,
    },
    {
      id: 5,
      imgSrc: galleryImg5,
    },
    {
      id: 6,
      imgSrc: galleryImg6,
    },
    {
      id: 7,
      imgSrc: galleryImg7,
    },
    {
      id: 8,
      imgSrc: galleryImg8,
    },{
      id: 9,
      imgSrc: galleryImg9,
    },
    {
      id: 10,
      imgSrc: galleryImg10,
    },
    {
      id: 11,
      imgSrc: galleryImg11,
    },
    {
      id: 12,
      imgSrc: galleryImg12,
    }
  ]

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');

  const getImg = (imgSrc) =>{
    setTempImgSrc(imgSrc);
    setModel(true);
  }

  return (
    <div className="bannerGalleryContainer">
      <h1>GALLERY</h1>
      <div className={model? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon className="imgClose" src={CloseIcon} onClick={() => setModel(false)} />
      </div>
      <section className="bannerGallery">
        
        {data.map((item, index) => {
          return(
            <div className="bannerGallerypics" key={index} onClick={() => getImg(item.imgSrc)}>
              <img className="bannerGallerypicsImg" src={item.imgSrc} style={{width:'100%'}} />
            </div>
          )
        })}
        
      </section>
    </div>
  )
}